import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/pages/HomePage.vue';
import TermsPage from '@/pages/TermsPage.vue';
import RulePage from '@/pages/RulePage.vue';
import PrivacyPage from '@/pages/PrivacyPage.vue';
import contactPage from '@/pages/contactPage.vue';
import locationPage from '@/pages/LocationPage.vue'
import MarketingPage from '@/pages/marketingPage.vue';
import PrivacyconsentPage from '@/pages/PrivacyconsentPage.vue';
import PgagreePage from '@/pages/pgagreePage.vue';

const routes = [
  {
    path: '/', // 기본 경로
    name: 'Home',
    component: HomePage,
    meta : {footer:'main'},
  },
  {
    path: '/contactPage',
    name: 'contactPage',
    component: contactPage,
    meta : {footer:'sub'},
  },
  
  {
    path: '/terms',
    name: 'TermsPage',
    component: TermsPage,
  },
  {
    path: '/rule',
    name: 'RulePage',
    component: RulePage,
  },
  {
    path: '/privacy',
    name: 'PrivacyPage',
    component: PrivacyPage,
  },
  {
    path: '/locationPage',
    name: 'locationPage',
    component: locationPage,
  },
  {
    path: '/location',
    name: 'location',
    component: locationPage,
  },
  {
    path: '/marketing',
    name: 'marketingPage',
    component: MarketingPage,
  },
  {
    path: '/privacyConsent',
    name: 'PrivacyConsentPage',
    component: PrivacyconsentPage,
  },
  {
    path: '/pgagree',
    name: 'PGAgree',
    component: PgagreePage,
  },
  {
    path: '/pgaree',
    name: 'PGAree',
    component: PgagreePage,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
